.dialog-header {
  font-family: "Nunito Sans", sans-serif;
  font-size: 22px;
}

.section-box {
  //border: solid 1px #99adb6;
  box-shadow: rgba(44, 52, 57, 0.15) 1px 1px 20px;
  border-radius: 8px;
  padding: 16px;
  overflow: hidden;
  background-color: white;
}

.section-title {
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
}

.section-divider {
  height: 1px;
  background-color: #99adb6;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}


.detail {
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
}

.history-row {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}
.history-date-column {
  min-width: 150px;
  padding: 8px;
  overflowWrap: "break-word";
}

.history-action-column {
  min-width: 150px;
  padding: 8px;
  overflowWrap: "break-word";
}

.history-detail-column {
  min-width: 180px;
  padding: 8px;
  overflowWrap: "break-word";
}

.history-payment-id-column {
  min-width: 300px;
  padding: 8px;
  overflowWrap: "break-word";
}

.history-transaction-id-column {
  min-width: 130px;
  padding: 8px;
  overflowWrap: "break-word";
}

.history-score-column {
  min-width: 100px;
  padding: 8px;
  overflowWrap: "break-word";
}

.bold{
  font-weight: bold;
}

.PhoneInputCountrySelectArrow {
  display: block !important;
  content: '' !important;
  width: 0.3em !important;
  height: 0.3em !important;
  margin-left: 0.35em !important;
  border-style: solid !important;
  border-color: inherit !important;
  border-top-width: 0 !important;
  border-bottom-width: 1px !important;
  border-left-width: 0 !important;
  border-right-width: 1px !important;
  transform: rotate(45deg) !important;
  opacity: 0.45 !important;
}

.history-title {
  color: #337AB7;
  padding-bottom: 10px;
  padding-top: 20px;
}

.history-table {
  border: solid 1px #99adb6;
  border-radius: 5px;
  overflow-x: auto;
}

.history-table-header {
  border-bottom: solid 1px #99adb6;
}

.action-disabled-no-payment {
  font-style: italic;
}