body {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
}

.nunito {
  font-family: "Nunito Sans", sans-serif;
}

.view-container {
  margin: 16px 40px;
}
