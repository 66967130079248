.custom-file-upload {
  padding: 5px 15px 5px 12px;
  background: #F5F7F8;
  border: 1px solid #668592;
  border-radius: 100px;
  cursor: pointer;
  font-size: 12px;
  color: #003349;
}

.filename {
  padding-left: 10px;
  color: #999999;
  font-size: 12px;
}