@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,400italic");
@import "~@vacasa/react-components-lib/fonts/wigrum";

:global .fontHeadingCard {
  font-family: "Wigrum", sans-serif;
  font-size: 24.03px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: -0.126474px;
  color: #2c3439;
}
:global .fontBodyRegularDefault {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #707579;
}
:global .fontBodyBoldDefault {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #2c3439;
}

:global .fontBodyRegularSmall {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4c5357;
}

:global .fontBodyBoldSmall {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #4c5357;
}

:global .fontBodySemiBoldSmall {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0078ab;
}

:global .fontBodyRegularTiny {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #4c5357;
}

:global .fontFieldLabel {
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0078ab;
}
