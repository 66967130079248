.table-container {
  border: solid 1px #99adb6;
  border-radius: 5px;
  background-color: #ffffff;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
