.dialog-container {
  padding: 16px;
}

.MuiDialogActions-root {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 16px;
}

.dialog-title {
  display: flex !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
  margin: 0px;
  padding: 8px 16px !important;
}

.close-button {
  margin: 0px;
  padding: 4px !important;
}

.confirmation-text {
  text-align: center;
  margin: 24px;
}
