.container-view {
  margin: 16px 40px;
}

.container-with-border {
  border: solid 1px #99adb6;
  border-radius: 5px;
  background-color: #ffffff;
}

.unit-allow-list-header {
  margin: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-with-bottom-border {
  border-bottom: solid 1px #99adb6;
}

.container-add-unit {
  margin: 16px;
  display: flex;
  align-items: center;
}

.container-body {
  margin: 32px;
}

.input-label {
  width: 240px;
}

.container-unit-allow-list {
  padding: 8px;
  overflow-y: auto;
  height: 400px;
}

.unit-chip {
  margin: 8px 8px !important;
}

.no-text-transform {
  text-transform: none !important;
}

.add-unit-button {
  width: 280px !important;
  margin-left: 32px !important;
}

.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.ml-8{
  margin-left: 8px !important;
}

.count-info {
  color: rgba(0, 0, 0, 0.87);
  margin-top: 4px;
  font-size: 12px;
}
