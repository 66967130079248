@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,400italic);
.dialog-header{font-family:"Nunito Sans",sans-serif;font-size:22px}.section-box{box-shadow:rgba(44,52,57,.15) 1px 1px 20px;border-radius:8px;padding:16px;overflow:hidden;background-color:#fff}.section-title{font-family:"Nunito Sans",sans-serif;font-size:18px}.section-divider{height:1px;background-color:#99adb6;width:100%;margin-top:8px;margin-bottom:8px}.detail{font-family:"Nunito Sans",sans-serif;font-size:13px}.history-row{display:flex;flex-direction:row;font-size:14px}.history-date-column{min-width:150px;padding:8px;overflowWrap:"break-word"}.history-action-column{min-width:150px;padding:8px;overflowWrap:"break-word"}.history-detail-column{min-width:180px;padding:8px;overflowWrap:"break-word"}.history-payment-id-column{min-width:300px;padding:8px;overflowWrap:"break-word"}.history-transaction-id-column{min-width:130px;padding:8px;overflowWrap:"break-word"}.history-score-column{min-width:100px;padding:8px;overflowWrap:"break-word"}.bold{font-weight:bold}.PhoneInputCountrySelectArrow{display:block !important;content:"" !important;width:.3em !important;height:.3em !important;margin-left:.35em !important;border-style:solid !important;border-color:inherit !important;border-top-width:0 !important;border-bottom-width:1px !important;border-left-width:0 !important;border-right-width:1px !important;-webkit-transform:rotate(45deg) !important;transform:rotate(45deg) !important;opacity:.45 !important}.history-title{color:#337ab7;padding-bottom:10px;padding-top:20px}.history-table{border:solid 1px #99adb6;border-radius:5px;overflow-x:auto}.history-table-header{border-bottom:solid 1px #99adb6}.action-disabled-no-payment{font-style:italic}
.flatpickr-day.selected{background:#0078ab !important;border-color:#0078ab !important}.flatpickr-day.endRange{background:#0078ab !important;border-color:#0078ab !important}.flatpickr-day.endRange:not(:nth-child(7n+1)){box-shadow:-10px 0 0 #0078ab !important}.flatpickr-day.startRange{background:#0078ab !important;border-color:#0078ab !important}.flatpickr-day.inRange{box-shadow:-5px 0 0 #f6f6f9,-5px 0 0 #f6f6f9 !important;background-color:#f6f6f9 !important;border-color:#f6f6f9 !important}.flatpickr-months .flatpickr-next-month:hover svg{fill:#333 !important}.flatpickr-months .flatpickr-prev-month:hover svg{fill:#333 !important}
@font-face{font-family:"Wigrum";src:url(/static/media/wigrumweb-light.06ce4f4e.woff) format("woff"),url(/static/media/wigrumweb-light.63eb24d0.eot) format("eot");font-weight:300;font-style:normal}@font-face{font-family:"Wigrum";src:url(/static/media/wigrumweb-medium.8a73a2b2.woff) format("woff"),url(/static/media/wigrumweb-medium.6d274971.eot) format("eot");font-weight:500;font-style:normal}@font-face{font-family:"Wigrum";src:url(/static/media/wigrumweb-bold.3422b66f.woff) format("woff"),url(/static/media/wigrumweb-bold.8145a360.eot) format("eot");font-weight:700;font-style:normal}.fontHeadingCard{font-family:"Wigrum",sans-serif;font-size:24.03px;font-weight:bold;line-height:28px;letter-spacing:-0.126474px;color:#2c3439}.fontBodyRegularDefault{font-family:"Nunito Sans",sans-serif;font-style:normal;font-weight:normal;font-size:16px;line-height:24px;color:#707579}.fontBodyBoldDefault{font-family:"Nunito Sans",sans-serif;font-style:normal;font-weight:bold;font-size:16px;line-height:24px;color:#2c3439}.fontBodyRegularSmall{font-family:"Nunito Sans",sans-serif;font-style:normal;font-weight:400;font-size:14px;line-height:20px;color:#4c5357}.fontBodyBoldSmall{font-family:"Nunito Sans",sans-serif;font-style:normal;font-weight:bold;font-size:14px;line-height:20px;color:#4c5357}.fontBodySemiBoldSmall{font-family:"Nunito Sans",sans-serif;font-style:normal;font-weight:600;font-size:14px;line-height:20px;color:#0078ab}.fontBodyRegularTiny{font-family:"Nunito Sans",sans-serif;font-style:normal;font-weight:normal;font-size:12px;line-height:16px;color:#4c5357}.fontFieldLabel{font-family:"Nunito Sans",sans-serif;font-style:normal;font-weight:600;font-size:16px;line-height:22px;color:#0078ab}
.loading-container{display:flex;flex:auto;align-items:center;justify-content:center}
.container-view{margin:16px 40px}.container-with-border{border:solid 1px #99adb6;border-radius:5px;background-color:#fff}.unit-allow-list-header{margin:16px;display:flex;justify-content:space-between;align-items:center}.container-with-bottom-border{border-bottom:solid 1px #99adb6}.container-add-unit{margin:16px;display:flex;align-items:center}.container-body{margin:32px}.input-label{width:240px}.container-unit-allow-list{padding:8px;overflow-y:auto;height:400px}.unit-chip{margin:8px 8px !important}.no-text-transform{text-transform:none !important}.add-unit-button{width:280px !important;margin-left:32px !important}.display-none{display:none}.visibility-hidden{visibility:hidden}.ml-8{margin-left:8px !important}.count-info{color:rgba(0,0,0,.87);margin-top:4px;font-size:12px}
.table-container{border:solid 1px #99adb6;border-radius:5px;background-color:#fff}.actions{display:flex;justify-content:center;align-items:center}
.table-toolbar-container{border-bottom:solid 1px #99adb6}.table-toolbar-title{margin:16px}.table-toolbar-children{margin-right:16px}
.dialog-container{padding:16px}.MuiDialogActions-root{display:flex !important;justify-content:center !important;align-items:center !important;margin-bottom:16px}.dialog-title{display:flex !important;justify-content:space-between !important;align-items:flex-end !important;margin:0px;padding:8px 16px !important}.close-button{margin:0px;padding:4px !important}.confirmation-text{text-align:center;margin:24px}
.linear-loading-container{width:100%}.linear-loading-default{height:4px}
.container-body-override{margin:0px}.container-with-margin{margin-left:30px;margin-right:30px}.container-with-bottom-border-light{border-bottom:solid 1px #e2e0e0}
.custom-file-upload{padding:5px 15px 5px 12px;background:#f5f7f8;border:1px solid #668592;border-radius:100px;cursor:pointer;font-size:12px;color:#003349}.filename{padding-left:10px;color:#999;font-size:12px}
body{margin:0;padding:0;font-family:"Nunito Sans",sans-serif}.nunito{font-family:"Nunito Sans",sans-serif}.view-container{margin:16px 40px}
