.container-body-override {
  margin: 0px;
}

.container-with-margin {
  margin-left: 30px;
  margin-right: 30px;
}

.container-with-bottom-border-light {
  border-bottom: solid 1px #E2E0E0;
}