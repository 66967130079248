.flatpickr-day.selected {
  background: #0078AB !important;
  border-color: #0078AB !important;
}

.flatpickr-day.endRange {
  background: #0078AB !important;
  border-color: #0078AB !important;
}

.flatpickr-day.endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #0078AB !important;
}

.flatpickr-day.startRange {
  background: #0078AB !important;
  border-color: #0078AB !important;
}

.flatpickr-day.inRange {
  box-shadow: -5px 0 0 #F6F6F9, -5px 0 0 #F6F6F9 !important;
  background-color: #F6F6F9 !important;
  border-color: #F6F6F9 !important;
}

.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #333333 !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #333333 !important;
}
